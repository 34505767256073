import { ApiService, ApiUrl } from "helpers";
import { observable, action } from "mobx";
import Alert from 'react-s-alert';

const candidateOwnerSettingStore = observable(
    {
        candidateOwners: [],
        defaultOwner: {},
        defaultJobOwner: {},
        prioritizeOwenerSettings: {},

        setCandidateOwners(candidateOwners) {
            this.candidateOwners = [];
            this.candidateOwners = [...candidateOwners];
        },

        setDefaultOwner(defaultOwner) {
            this.defaultOwner = {};
            this.defaultOwner = { ...defaultOwner };
        },

        setDefaultJobOwner(defaultJobOwner) {
            this.defaultJobOwner = { ...defaultJobOwner };
        },

        resetCandidateOwnerSettings() {
            this.candidateOwners = [];
            this.defaultOwner = {};
            this.defaultJobOwner = {};
            this.prioritizeOwenerSettings = {};
        },

        setPrioritizeOwenerSettings(prioritizeOwenerSettings) {
            this.prioritizeOwenerSettings = {...prioritizeOwenerSettings};
        },

        getCandidateOwners() {
            return (ApiService.get(ApiUrl.getCandidateOwners).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setCandidateOwners(response.data.result);
                            this.setDefaultOwner(response.data.result.find(x => x.isDefault) || {});
                            this.setDefaultJobOwner(response.data.result.find(x => x.isDefaultJobOwner) || {});
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getPrioritizeOwnerSetting() {
            return (ApiService.get(ApiUrl.getPrioritizeOwner).then(
              action(
                response => {
                  if (response && response.status === 200 && response.data && !response.data.isError) {
                    this.setPrioritizeOwenerSettings(response.data.result);
                  } else {
                    Alert.error("Oops, Something went wrong! Please try again.");
                  }
                  return Promise.resolve(response.data);
                }
              )
            ).catch(
              action(
                err => {
                  return Promise.reject(err);
                }
              )
            ));
        },

        updatePrioritizeOwnerSetting(payload) {
            return ApiService.edit(ApiUrl.updatePrioritizeOwner, payload).then(
                action(
                  response => {
                    if (response && response.status === 200 && response.data && !response.data.isError) {
                    } else {
                      Alert.error("Oops, Something went wrong! Please try again.");
                    }
                    return Promise.resolve(response.data);
                  }
                )
              ).catch(
                action(
                  err => {
                    return Promise.reject(err);
                  }
                )
            );
        },

        submit() {
            return (ApiService.add(ApiUrl.updateDefaultOwners, this.defaultOwner, "Data updated successfully").then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {

                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err)
                    }
                )
            ));
        },

        submitDefaultJobOwner() {
            return (ApiService.add(ApiUrl.updateDefaultJobOwners, this.defaultJobOwner, "Data updated successfully").then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {

                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err)
                    }
                )
            ));
        }
    }
);

export default candidateOwnerSettingStore;
