const ApiUrl = {
  // account
  dashboardLogin: "/Users/Login",
  forgotPassword: "/Users/ForgotPassword",
  resetPassword: "/Users/ResetPassword/",
  setupMfa: "/Users/SetupMfa",
  confirmMfa: "/Users/ConfirmMfa",

  // user
  addUser: "/Users/Create",
  getAllUser: "/Users/GetUsers",
  changePassword: "/Users/ChangePassword",
  deleteUser: "/Users/",
  updateUser: "/Users/Update",
  detailUser: "/Users/",
  resetMfa: "/Users/ResetMfa",

  // chatbot
  addChatbot: "/Chatbots/Create",
  updateChatbot: "/Chatbots/Update",
  updateChatbotAttachment: "/Chatbots/UpdateAttachment",
  manageChatbot: "/Chatbots/Manage",
  getChatbotById: "/Chatbots/",
  updatePublishStatus: "/Chatbots/UpdatePublishStatus",
  deleteChatbot: "/Chatbots/",
  detailLiveChatbot: "/Chatbots/DetailLink/",
  widgetChatbot: "/Chatbots/Widget/",
  detailTestChatbot: "/Chatbots/DetailLinkTest/",
  filter: "/Chatbots/Filter",

  //contact
  getCandidateDetails: "/Contacts/GetCandidate/",
  getCandidateAtsDetails: "/Contacts/GetCandidateAtsDetail/",
  getCandidateAtsDetailsByToken: "/Contacts/GetCandidateAtsDetailPublic/",
  getClientDetails: "/Contacts/GetClient/",
  getLeadDetails: "/Contacts/GetLead/",
  getAllCandidates: "/Contacts/GetCandidates",
  getAllClients: "/Contacts/GetClients",
  getAllLeads: "/Contacts/GetLeads",
  getContactResume: "/Contacts/GetContactResume/",
  addContact: "/Contacts/Create",
  deleteContacts: "/Contacts/Delete",
  checkExistingEmail: "/Contacts/CheckEmail",
  checkExistingMobile: "/Contacts/CheckPhone",
  updateContact: "Contacts/Update",
  convertLead: "/Contacts/Convert",
  archiveOrRestoreLead: "/Contacts/ArchiveOrRestore",
  updateCandidateAts: "/Contacts/UpdateAtsCandidate/",
  updateCandidateAtsFromPublic: "/Contacts/UpdateAtsCandidatePublic/",
  unsubscribeCandidate: "/Unsubscribes/UnsubscribeCandidateDetail",
  unsubscribeClient: "/Unsubscribes/UnsubscribeClientDetail",

  //clients
  getClientAtsDetails: "/Contacts/Client/AtsDetail/",
  updateClientAts: "/Clients",

  //audience candidate
  getAudienceDetails: "/Audiences/GetAudienceDetail/",
  addAudience: "/Audiences/Create",
  updateAudience: "/Audiences/Update",
  getAllAudience: "/Audiences/GetAudiences",
  deleteAudience: "/Audiences/Delete",
  unsubscribeAudienceMember: "/Unsubscribes/UnsubscribeAudienceMember",
  deleteAudienceMember: "/AudienceMembers/Delete",
  unsubscribeAudienceMemberFromPublic: "/Unsubscribes/UnsubscribeFromEmail",

  //audience client
  getAudienceClientDetails: "/AudienceClients/",
  unsubscribeClientFromPublic: "/UnsubscribeClients/UnsubscribeFromPublic",
  unsubscribeClientFromAudience: "/UnsubscribeClients/UnsubscribeFromAudience/",

  //audience member
  addAudienceMembers: "/AudienceMembers/InsertAudienceMember",
  getAudienceMembers: "/AudienceMembers/",
  getAudienceClientMembers: "/AudienceClientMembers/",
  getContactList: "/AudienceMembers/GetContactList",

  //audienceClient
  getAudienceClients: "/AudienceClients",
  deleteAudienceClient: "/AudienceClients",

  //gridFilter
  getAllFilter: "/GridFilters/GetFilters",
  detailFilter: "/GridFilters/",
  addFilter: "/GridFilters/Create",
  updatefilter: "/GridFilters/Update,",
  deleteFilter: "/GridFilters/",

  //companies
  getAllCompany: "/Companies/GetCompanies",
  addCompany: "/Companies/Create",
  updateCompany: "/Companies/Update",
  validateMessageSetting: "/Companies/ValidateMessageSetting",
  deleteCompany: "/Companies/",
  getCompanyById: "/Companies/",
  getCompanyByName: "/Companies/GetCompanyByName",
  atsAuthentication: "/Companies/AtsAuthentication",
  syncNow: "/Companies/SyncNow",
  syncFields: "/Companies/SyncFields",
  getCompanyAdditionalInfo: "/Companies/GetCompanyAdditionalInfo",

  //chatbotTemplate
  addChatbotTemplate: "/ChatbotTemplates/Create",

  //report
  getActivityLogs: "/ActivityLogs/GetActivityLogs/",
  getEmailReports: "/Reports/Emails/",
  getAllEmailReports: "/Reports/Emails/All",
  getEmailReportSelectedDetails: "/Reports/Email/",
  getEmailReportLink: "/Reports/Email/Link/",
  getEmailReportClicked: "/Reports/Emails/Clicked/",
  getEmailReportLinkDetails: "/Reports/Email/Link/Clicked",
  getSurveyReports: "/Reports/Survey",
  getSurveyGroupReports: "/Reports/SurveyGroup",
  getAllSurveyGroupReports: "/Reports/SurveyGroup/All",
  getAutomationReports: "/Reports/Automation/",
  getTextReports: "/Reports/Text/",
  getTextReportSelected: "/Reports/Texts/",
  getTextReportLink: "/Reports/Text/Link/",
  getTextReportLinkDetail: "/Reports/Text/Link/Clicked/",
  getTextReportClicked: "/Reports/Text/CLicked",
  getTextReportDelivered: "/Reports/Text/Delivered",
  getAllAutomationReports: "/Reports/Automation/All",
  getAutomationReportDetails: "/Reports/Automation/",
  getAutomationReportCompletions: "/Reports/Automation/StepDetail/",
  getAutomationReportClientCompletions: "/ReportClients/Automation/StepDetail/",
  getSurveyCompletion: "/Reports/SurveyCompletionDetail/",
  getSurveyCompletionReport: "/Reports/TotalCompletionSurvey",
  getSurveyOpenReport: "/Reports/TotalOpenSurvey",
  getSurveyReportTotalOpened: "/Reports/Survey/TotalOpened",
  getSurveyReportTotalCompletion: "/Reports/Survey/TotalCompletion",
  getSurveyCompletionResponses: "/Reports/SurveyCompletionResponses/",
  getSurveyReportCsv: "/Reports/Survey/Csv",
  
  //survey report clients
  getSurveyReportsClient: "/ReportClients/Survey",
  getSurveyGroupReportsClient: "/ReportClients/SurveyGroup",
  getSurveyClientCompletion: "/ReportClients/SurveyCompletionDetail/",
  getSurveyClientReportTotalOpened: "/ReportClients/Survey/TotalOpened",
  getSurveyClientReportTotalCompletion: "/ReportClients/Survey/TotalCompletion",
  getSurveyClientCompletionResponses: "/ReportClients/SurveyCompletionResponses/",
  getSurveyReportClientCsv: "/ReportClients/Survey/Csv",

  //report email clients
  getEmailClientReports: "/ReportClients/ClientEmails/",
  getEmailReportClientSelectedDetails: "/ReportClients/ClientEmail/",
  getEmailReportClientLink: "/ReportClients/ClientEmails/Link/",
  getEmailReportClientClicked: "ReportClients/ClientEmail/Clicked/",
  getEmailReportClientLinkDetails: "ReportClients/ClientEmail/Link/Clicked",
  getSurveyOpenReportClient: "/ReportClients/TotalOpenSurvey",
  getSurveyCompletionReportClient: "/ReportClients/TotalCompletionSurvey",

  //report automation client
  getAutomationClientReports: "ReportClients/Automation/Client",
  getAutomationReportClientDetails: "/ReportClients/Automation/Client/",

  //job
  addJob: "Jobs/Create",
  getAllJob: "Jobs/GetJobs/",
  getAllClientJob: "Jobs/GetClientJobs/",
  getJobDetails: "/Jobs/GetJobsDetail/",
  updateJob: "/Jobs/UpdateJobDetail",
  archiveOrRestoreJob: "/Jobs/ArchiveOrRestore/",
  getJobCandidate: "/Jobs/GetJobCandidates/",
  getCandidatesForJob: "Jobs/GetCandidatesForJob/",
  addCandidateToJob: "/Jobs/AddcandidateToJob",

  //campaign
  sendCampaign: "/Campaign/Send",
  sendCampaignClient: "/Campaign/SendClient",
  getCampaignByType: "/ActivityLogs/GetCampaignByFeatureType/",
  sendTestMail: "/Campaign/SendTestMail",
  newSendTestMail: "/Campaign/NewSendTestMail",
  sendTestAlert: "/Alert/SendTestAlert",

  //acvitityLog
  getContactActivity: "/ActivityLogs/GetContactActivityLogs/",
  getClientActivity: "/ActivityLogs/Client/",

  // general setting
  getCompanySetting: "/GeneralSettings/Detail/",
  updateCompanySetting: "/GeneralSettings/Update",
  createCompanySetting: "/GeneralSettings/Create",
  getLengthFooterText: "/GeneralSettings/LengthFooterText",
  getPrioritizeOwner: "/GeneralSettings/GetPrioritizeOwners",
  updatePrioritizeOwner: "/GeneralSettings/UpdatePrioritizeOwners",
  getTimeZones: "/GeneralSettings/GetTimeZone/",
  updateTimeZone: "/GeneralSettings/UpdateTimeZone/",
  getCompanyColors: "/GeneralSettings/GetCompanyColor",
  createCompanyColor: "/GeneralSettings/CreateCompanyColor",
  updateCompanyColor: "/GeneralSettings/UpdateCompanyColor",
  deleteCompanyColor: "/GeneralSettings/DeleteCompanyColor/",


  // lookup
  getJAWorkTypes: "/Lookup/Jobadder/WorkTypes",
  getJARatings: "/Lookup/Jobadder/Ratings",
  getTagSuggestions: "/Lookup/GetTagSuggestion/",
  getPlacementStatuses: "/Lookup/JobAdder/PlacementStatuses",
  getApplicationStatuses: "/Lookup/JobAdder/ApplicationStatuses",
  getJobStatuses: "/Lookup/JobAdder/JobStatuses",
  getCandidateAttachmentCategories: "/Lookup/JobAdder/CandidateAttachmentCategories",

  // getJASources: "/Lookup/Jobadder/Sources",
  getJACandidateStatuses: "/Lookup/Jobadder/CandidateStatuses",
  getJAWorkTypesPublic: "/Lookup/Jobadder/WorkTypesPublic",
  getJARatingsPublic: "/Lookup/Jobadder/RatingsPublic",
  getJACandidateStatusesPublic: "/Lookup/Jobadder/CandidateStatusesPublic",
  getJACandidateNoteStatuses: "/Lookup/Jobadder/CandidateNoteStatuses",
  getJAClientStatuses: "/Lookup/Jobadder/ClientStatuses",
  getJAClientNoteStatuses: "/Lookup/Jobadder/ClientNoteStatuses",
  getJACountries: "/Lookup/Jobadder/Countries",
  getJACountriesPublic: "/Lookup/Jobadder/CountriesPublic",
  getJobApplicationSources: "/Lookup/Jobadder/JobApplicationSources",
  getCustomFieldsPublic: "Lookup/Candidate/CustomFieldsPublic",

  // link trackers for trigger
  getActivityLogName: "/ActivityLogs/GetActivityLogName",
  getClientActivityLogName: "/ActivityLogs/GetClientActivityLogName",

  //email campaign
  addEmailCampaign: "/EmailCampaigns/Create",
  updateEmailCampaign: "/EmailCampaigns/Update",
  getEmailCampaignDetail: "/EmailCampaigns/Detail/",
  getListEmailCampaign: "/EmailCampaigns/List",
  deleteEmailCampaign: "/EmailCampaigns/Delete/",
  getAllEmailCampaign: "/EmailCampaigns/GetAll/",
  getEmailCampaignLinks: "/EmailCampaigns/GetEmailCampaignLink/",
  IsEmailCampaignUsedInAutomation: "/EmailCampaigns/IsEmailCampaignUsedInAutomation/",
  copyEmailToExisting: "/EmailCampaigns/CreateCopy",
  getEmailCampaignIsNameInUse: "/EmailCampaigns/GetIsNameInUse",
  postEmailCampaignSaveAsNew: "/EmailCampaigns/SaveAsNew",

  //email template
  copyEmailToTemplate: "/EmailTemplates/Create",
  getListEmailTemplate: "/EmailTemplates/List",
  getEmailTemplateDetail: "/EmailTemplates/Detail/",
  deleteEmailTemplate: "/EmailTemplates/Delete/",
  updateEmailTemplate: "/EmailTemplates/Update",
  getEmailTemplateIsNameInUse: "/EmailTemplates/GetIsNameInUse",
  emailTemplateSetShowToRecruiter: "/EmailTemplates/SetShowToRecruiter",

  //Save Rows
  getListSaveRow: "/EmailTemplates/ListSaveRow",
  getSaveRow: "/EmailTemplates/GetSaveRow/",
  createSaveRow: "/EmailTemplates/CreateSaveRow",
  updateSaveRow: "/EmailTemplates/UpdateSaveRow",
  deleteSaveRow: "/EmailTemplates/DeleteSaveRow/",

  //ATS Field
  getAtsField: "/AtsFields/GetAtsFields",
  getLandingPageAtsField: "/AtsFields/GetLandingPageAtsFields",
  setAtsField: "/AtsFields/SetFieldState",
  setAllAtsField: "/AtsFields/SetAllFieldState",
  customFields: "/AtsFields/Candidate/CustomFields",
  getAtsCategorisedFields: "/AtsFields/GetAtsCategorisedFields",

  // Automation
  getAutomations: "/Automations/GetAutomations",
  getAutomationDetail: "/Automations/",
  addAutomation: "/Automations/CreateAutomation",
  deleteAutomation: "/Automations/",
  setAutomationState: "/Automations/SetAutomationState",
  updateAutomation: "/Automations/Update",
  getTriggerMatchesNumber: "/Automations/GetMatchesNumber",
  getCandidateCustomFields: "/CandidateCustomFields/GetCandidateCustomField",
  automationSaveAsExisting: "/Automations/CreateCopy",
  getAutomationAudienceList: "/Audiences/List",
  getAutomationAudienceClientList: "/AudienceClients/List",
  getClientCustomFields: "/ClientCustomFields",
  getIsNameInUse: "/Automations/GetIsNameInUse",
  postSaveAsNew: "/Automations/SaveAsNew",

  //Domain Authentication
  getCompanyDomain: "/CompanyDomain/Detail",
  getCompanyDomainDetailById: "/CompanyDomain/GetDomainDetailById",
  addCompanyDomain: "/CompanyDomain/Register",
  verifyCompanyDomain: "/CompanyDomain/Verify",
  setDomainDefault: "/CompanyDomain/SetDefaultDomain",
  deleteCompanyDomain: "/CompanyDomain/Delete/",

  //Shortlink
  getShortlinkByToken: "/LinkTracker/GetOriginalUrl/",
  getShortlinkClientByToken: "/LinkTracker/OriginalUrl/Client/",
  getConvertLink: "/Campaign/ShortenLink",
  getBaitlink: "/LinkTracker/BaitLink/",

  //survey
  getListSurveys: "/Surveys/GetSurveys",
  getSurveyTags: "/Surveys/GetSurveyTags",
  getSurveyDetail: "/Surveys/",
  getSurveyByToken: "/Surveys/GetSurveyByToken/",
  getSurveyClientByToken: "Surveys/Client/",
  addSurvey: "/Surveys/CreateSurvey",
  deleteSurvey: "/Surveys/",
  setSurveyState: "/Surveys/SetSurveyState",
  submitSurvey: "/Surveys/SubmitSurvey/",
  submitSurveyClient: "/Surveys/Client/Submit/",
  updateSurvey: "/Surveys/UpdateSurvey/",
  getAllSurveys: "/Surveys/GetAll",
  getSurveyIsNameInUse: "Surveys/GetIsNameInUse",
  postSurveySaveAsNew: "Surveys/SaveAsNew",
  
  //survey template
  getSurveyTemplateDetail: "/SurveyTemplates/",
  getSurveyTemplateList: "/SurveyTemplates/GetSurveyTemplates",
  saveSurveyToTemplate: "/SurveyTemplates/Create",
  updateSurveyTemplate: "/SurveyTemplates/Update",
  deleteSurveyTemplate: "/SurveyTemplates/Delete/",
  saveSurveyToExisting: "/Surveys/CreateCopy",

  // Recruiter
  getCandidateOwners: "/CandidateOwners/GetCandidateOwners",
  updateDefaultOwners: "/CandidateOwners/SetDefaultCandidateOwner",
  updateDefaultJobOwners: "/CandidateOwners/SetDefaultJobOwner",

  //Email footer
  getEmailFooterSetting: "/EmailFooterTemplates/Get",
  updateEmailFooterSetting: "/EmailFooterTemplates/Update",
  saveSendUnsubscribeNoteStatus: "/EmailFooterTemplates/SaveSendUnsubscribeNoteStatus",

  //Automation Templates
  getAutomationTemplates: "/AutomationTemplates/GetAutomationTemplates",
  automationSaveAsTemplate: "/AutomationTemplates/Create",
  getAutomationTemplateDetail: "/AutomationTemplates/",
  updateAutomationTemplate: "/AutomationTemplates/Update",
  deleteAutomationTemplates: "/AutomationTemplates/",
  automationSetShowToRecruiter: "/AutomationTemplates/SetShowToRecruiter",
  getIsTemplateNameInUse: "/AutomationTemplates/GetIsNameInUse",

  //Web activity tracker
  getWebActivityTrackerSetting: "/WebActivityTracking",
  updateWebActivityTrackerSetting: "/WebActivityTracking/Update",
  deleteWebActivityTrackerSetting: "/WebActivityTracking/Delete",

  //Segmentation
  getUserJobAdders: "/Segmentation/GetUserJobAdders",
  getOfficeJobAdders: "/Segmentation/GetOfficeJobAdders",
  getGroupJobAdders: "/Segmentation/GetGroupJobAdders",
};

export default ApiUrl;
